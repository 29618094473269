.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px;
  color: white;
}

.App-logo {
  height: 40px;
  margin-right: 10px;
}

h1 {
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0;
}

main {
  padding: 20px;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 8px;
}

h2 {
  color: #282c34;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}