@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

.header {
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: center;
	height: 70px;
}
  
.logo {
    height: 45px;
    margin-right: 10px;
}
  
.nav {
    display: flex;
    align-items: center;
}
  
.navList {
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    text-decoration: none;
    list-style-type: none;
    padding: 25;
}
  
.navItem {
    display: inline;
    margin-right: 50px;
    position: relative;
}
  
.navLink {
    color: ghostwhite;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 26px;
    font-weight: bold;
	font-style: italic;
}

.psLink {
    color: lightcoral;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 26px;
    font-weight: bold;
}
  
.visible {
    display: block !important;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: black;
    text-decoration: none;
}

.dropdownItem {
    text-decoration: none;
    color: white;
    padding: 12px 16px;
    display: block;
}  

.navItemSpecial {
    margin-left: auto;
    margin-right: 0; 
}
  
.psbetText {
    background-color: #efc442; /* Highlighter yellow background */
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 10px; /* Adjust padding as needed */
    display: inline-block;
    border-radius: 5px; /* Optional: for rounded corners */
}
  
.ps {
    color: rgb(0, 0, 0);
}
  
.bet {
    color: red;
}
