.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding: 2em;
  }
  
  .title {
    font-size: 3em;
    text-align: center;
    color: black;
    font-family: 'Bungee Tint', cursive;
  }
  
  .subtitle {
    font-size: 2em;
    text-align: center;
    color: #292929;
    margin-bottom: 1em;
    font-family: 'Helvetica';
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin-bottom: 2em;
    flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
  }
  
  .button-container img {
    width: 250px;
    height: auto;
    margin: 0.5em; /* Adds spacing between buttons on smaller screens */
  }
  
  /* Media Queries for Mobile Devices */
  @media (max-width: 768px) {
    .title {
      font-size: 2em;
    }
  
    .subtitle {
      font-size: 1.5em;
    }
  
    .button-container {
      width: 90%;
    }
  
    .button-container img {
      width: 100%;
      max-width: 300px;
    }
  
    table {
      width: 100%;
    }
  }
  